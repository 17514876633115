import { SxProps } from "@mui/material";

export const compactTableCellStyle: SxProps = {
  py: 0.25,
  px: { xs: 0.2, md: 0.5 },
  whiteSpace: "noWrap",
};

export const contentBlock = (theme: any, display: string) => ({
  display: display,
  p: display === "inline-block" ? 0.25 : 1,
  bgcolor: theme.palette.mode === "dark" ? "rgba(16,16,16,0.6)" : "#fff",
  color: theme.palette.mode === "dark" ? "grey.300" : "grey.800",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "grey.800" : "grey.300",
  borderRadius: 2,
  fontSize: "0.875rem",
  fontWeight: "700",
});
