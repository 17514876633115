import React from "react";
import { Typography, TypographyProps } from "@mui/material";

const TableNameText = (props: TypographyProps) => {
  return (
    <Typography
      {...props}
      component={"span"}
      color="primary.main"
      sx={{
        fontSize: "0.9rem",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "auto",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default TableNameText;
